import React from 'react';

const Result = () => {
    return (
        <div className="result">
            <h2>शैक्षिक सत्र २०७७को वार्षिक परीक्षाफल</h2>
            <p className={"result-paragraph"}>
                आदरणिय अभिभावक ज्यू हरु नमस्कार गत साल बाटनै कोरोना भाईरसको कारण असहज बनेको शैक्षिक क्षेत्र अन्त्यतिर
                शुरु गरिएको शै.स.२०७७ को पनि अन्तिम परीक्षाको बेलामा विद्यालय बन्दगर्नु पर्ने परिस्थिती सिर्जना भई
                विद्यार्थीको प्रथम त्रैमासिक परीक्षा,दोस्रो त्रैमासिक परीक्षा लगायत उनिहरुले कक्षामा देखाएको पढाई
                प्रतिको अग्रसरता समेतलाई ध्यानमा राखी कक्षा नर्सरी देखि कक्षा ८ सम्मको शै.स.२०७७ को वार्षिक नतिजा
                प्रकाशन गरेका छौ ।आशा छ हाल भैरहेको यो अत्यन्त असहज परिस्थितीको छिट्टै अन्त्य भई विद्यार्थीहरुको पढ्ने
                वातावरण छिट्टै बन्ने छ र भौतिक रुपमा तत्काल उपस्थित हुन नसकिने समयावधिमा समेत वैकल्पिक माध्यम बाट
                विद्यार्थीको सिकाई प्रक्रीया शुरु हुने अपेक्षाका साथ यो महामारीको समयमा आँफु र आफ्ना वालवालिका हरुलाई
                सुरक्षित राख्नु हुन अनुरोध गर्दै उत्रिर्ण हुने सबै विद्यार्थीहरुको नामावली यसै साथ प्रकाशित गरेका छौं र
                सबै कक्षाका सम्पुर्ण सफल विद्यार्थी बाबु नानीहरुलाई हार्दिक बधाई तथा शैक्षिक सत्र २०७८ सालमा अझ शैक्षिक
                उन्नती प्रगती हुदै जावस भन्ने कामना ब्यक्त गर्दछौ ।
                <br/>
                <br/>
                <p>
                    <strpng>प्रधानाध्यापक</strpng>
                </p>
            </p>
            <a href="results/nursery-2077.pdf">Nursery</a>
            <br/>
            <a href="results/kg-2077.pdf">KG</a>
            <br/>
            <a href="results/1-2077.pdf">Class 1</a>
            <br/>
            <a href="results/2-2077.pdf">Class 2</a>
            <br/>
            <a href="results/3-2077.pdf">Class 3</a>
            <br/>
            <a href="results/4-2077.pdf">Class 4</a>
            <br/>
            <a href="results/5-2077.pdf">Class 5</a>
            <br/>
            <a href="results/6-2077.pdf">Class 6</a>
            <br/>
            <a href="results/7-2077.pdf">Class 7</a>
            <br/>
            <a href="results/8-2077.pdf">Class 8</a>
        </div>
    );
};

export default Result;
